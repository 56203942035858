import axios from 'axios';
import dayjs from 'dayjs';
import qs from 'qs';
import type { IpeData } from '~/types/API/analytics';
import type { Consumption } from '~/types/API/consumption';
import type { Meter } from '~/types/meter';

export const useApi = () => {
  // Composables
  const config = useRuntimeConfig();
  const { fbAuth } = useFirebase();

  // Variables
  let userTokenId: string = '';

  // Api Config
  const apiConfig = {
    returnRejectedPromiseOnError: true,
    timeout: 60000,
    baseURL: config.public.backendUrl,
    paramsSerializer: {
      encode: (params: any) => qs.stringify(params, { indices: false }),
    },
  };
  const apiClient = axios.create(apiConfig);

  // Auth Functions
  const getToken = async () => {
    const user: any = await fbAuth.currentUser;
    const token: string = await user?.getIdToken();
    userTokenId = token;
  };
  const getHeaders = async () => {
    await getToken();
    const h = {
      headers: {
        common: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache',
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${userTokenId}`,
        },
      },
    };
    return h;
  };

  const postUser = async (id: string, data: any) => {
    const resultAxios = await apiClient.post(`/v1/user/${id}`, data, await getHeaders());
    return resultAxios?.data;
  };

  const getConsumptionByMeter = async (options: {
    meterId: number;
    start: string;
    end: string;
    aggregationLevel: string;
  }): Promise<Consumption[]> => {
    const headers = await getHeaders();
    let resultAxios: any = null;

    if (options.aggregationLevel === 'hour' || options.aggregationLevel === 'minute') {
      const interval = options.aggregationLevel === 'hour' ? 60 : 5;
      resultAxios = await apiClient.get(
        `/v1/consumption/detailed?meter_id=${options.meterId}&start=${options.start}&end=${options.end}&interval=${interval}`,
        headers,
      );
    } else {
      resultAxios = await apiClient.get(
        `/v1/consumption?meter_id=${options.meterId}&start=${options.start}&end=${options.end}&aggregation_level=${options.aggregationLevel}`,
        headers,
      );
    }

    return resultAxios?.data;
  };

  const getOffProductionByMeterId = async (options: { meterId: number; start: string; end: string }) => {
    const resultAxios: any = await apiClient.get(
      `/v1/off_production/${options.meterId}?start=${options.start}&end=${options.end}`,
      await getHeaders(),
    );
    return resultAxios?.data;
  };

  const getIpeData = async (options: { id: number; start: string; end: string }): Promise<IpeData> => {
    const resultAxios: any = await apiClient.get(
      `/v1/energy_performance_index/${options.id}?start=${options.start}&end=${options.end}`,
      await getHeaders(),
    );
    return resultAxios?.data;
  };

  const getAnalyticDataByMonth = async (meterTypes: string[] = []): Promise<{ meter: Meter; consumption: Consumption[] }[]> => {
    const meterTypesOrDefault = meterTypes.length === 0 ? ['enedis', 'grdf'] : meterTypes;

    const meters = meterStore().getMeters.filter((m) => meterTypesOrDefault.includes(m.meter_type.name));

    const promises = meters.map((meter) => {
      return getConsumptionByMeter({
        meterId: meter.id,
        start: dayjs().subtract(18, 'month').date(1).format('YYYY-MM-DD'),
        end: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        aggregationLevel: 'month',
      }).then((consumption) => {
        return {
          meter,
          consumption,
        };
      });
    });

    return Promise.all(promises);
  };

  const getObjectiveConsumption = async (id: string) => {
    const resultAxios: any = await apiClient.get(`/v1/consumption/objective/${id}`, await getHeaders());
    return resultAxios?.data;
  };

  const getContractAnalysis = async (analyticId: string | number) => {
    const resultAxios: any = await apiClient.get(`/v1/contract_analysis/detailed/${analyticId}`, await getHeaders());
    return resultAxios?.data;
  };

  return {
    getConsumptionByMeter,
    getObjectiveConsumption,
    getContractAnalysis,
    getIpeData,
    getOffProductionByMeterId,
    postUser,
    getAnalyticDataByMonth,
  };
};
